@font-face {
    font-family: 'mikeicon';
    src:
      url('../webfonts/mikeicon.ttf?e0o763') format('truetype'),
      url('../webfonts/mikeicon.woff?e0o763') format('woff'),
      url('../webfonts/mikeicon.svg?e0o763#seedzicon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="mikeicon-"], [class*=" mikeicon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'mikeicon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .mikeicon-mobile-menu:before {
    content: "\e919";
  }
  .mikeicon-x:before {
    content: "\e91a";
    cursor: pointer;
  }
  .mikeicon-home:before {
    content: "\e906";
  }
  .mikeicon-checkmarkcircle:before {
    content: "\e907";
  }
  .mikeicon-checkmark:before {
    content: "\e908";
  }
  .mikeicon-repeating-event:before {
    content: "\e909";
  }
  .mikeicon-filter:before {
    content: "\e90a";
  }
   .mikeicon-filter.active:before {
    content: "\e90a";
    color: $color-brand;
  }
  // .mikeicon-type-meeting:before {
  //   content: "\e90b";
  // }
  // .mikeicon-type-rehersal:before {
  //   content: "\e90c";
  // }
  .mikeicon-plus:before {
    content: "\e90d";
  }
  .mikeicon-pluscircle:before {
    content: "\e90e";
  }
  .mikeicon-caret-l:before {
    content: "\e90f";
  }
  .mikeicon-caret-u:before {
    content: "\e910";
  }
  .mikeicon-caret-r:before {
    content: "\e911";
  }
  .mikeicon-caret-d:before {
    content: "\e912";
  }
  .mikeicon-dots-h:before {
    content: "\e913";
  }
  .mikeicon-dots-v:before {
    content: "\e914";
  }
  .mikeicon-goals:before {
    content: "\e915";
  }
  .mikeicon-finances:before {
    content: "\e916";
  }
  .mikeicon-schedule:before {
    content: "\e917";
  }
  .mikeicon-tasks:before {
    content: "\e918";
  }  
  .mikeicon-arrow:before {
    content: "\e900";
  }
  .mikeicon-email:before {
    content: "\e901";
  }
  .mikeicon-fb:before {
    content: "\e902";
  }
  .mikeicon-password:before {
    content: "\e903";
  }
  .mikeicon-tw:before {
    content: "\e904";
  }
  .mikeicon-user:before {
    content: "\e905";
  } 
  
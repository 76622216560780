@import '_mixins.scss';

.list {
    margin-bottom: $vertical-el-distance;
    .progress {
        height: 26px;
        position: relative;
    }
    .progress-bar,
    .progress-bar--green {
        color: $color-dark;
        font-size: 1.2rem;
        font-weight: 700;

        z-index: 2;
    }
    .progress-total {
        position: absolute;
        display: inline-flex;
        align-self: center;
        justify-content: flex-end;
        right: 0;
        top: 0;
        height: 100%;
        padding-right: 8px;
        opacity: .8;
        padding-top: 1px;
    }
}
.list-head {
    background-color: rgba(0,0,0,.1);
    display: flex;
    padding: 10px 40px;
    margin-bottom: 2px;
    box-shadow: inset 4px 0 0 rgba(255,255,255,.5);
    h3 {
        flex: 1 1 auto;
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        i {
            opacity: .65;
        }
    }
    @include mediaMax($breakpoint-md) {
        padding: 10px 16px;
        h3 {
            font-size: 1rem;
        }
    } 
}
.list-head-actions {
    display: flex;
    align-items: center;
    > * {
        margin-left: 15px;
    }
}
.list-head-more {
    color: rgba(255,255,255,.3);
    text-transform: uppercase;
    font-size: .8rem;
    a {
        color: rgba(255,255,255,.5);
        &:hover {
            color: #fff;
        }
        i {
            margin-left: 4px;
            position: relative;
            top: 1px;
            opacity: .8;
        }
    }
    @include mediaMax($breakpoint-md) {
        .text {
            display: none;
        }
        i {
            margin-left: 0;
        }
    }
}
.distancer-h {
    padding: 0 8px;
}
.list-row {
    display: flex;
    background-color: rgba(0,0,0,.2);
    transition: .2s all;
    margin-bottom: 1px;
   // position: relative;
    p {
        margin: 0;
        line-height: 1.3em;
        font-size: 1.1rem;
    }
    .badge {
        position: relative;
        top: -2px;
    }
    .mikeicon-type-rehersal,
    .mikeicon-type-meeting {
        font-size: 1.6rem;
        line-height: 1em;
    }
    .mikeicon-repeating-event {
        opacity: .5;
    }
    @include mediaMax($breakpoint-md) {
        flex-direction: column;
    } 
}
.list-row-completed {
    p {
        text-decoration: line-through;
        opacity: .4;
    }
}
.list-row-center {
    @include mediaMin($breakpoint-md) {
        align-items: center;
    }
}
.list-row-link {
    color: #fff;
    &:hover {
        color: #fff;
        background-color: rgba(0,0,0,.35);
    }
}
.list-row-due-today {
    box-shadow: inset 4px 0 0 $color-brand;
    .badge-status {
        color: $color-brand;
        border-color: $color-brand;
    }
}
.list-row-overdue {
    box-shadow: inset 4px 0 0 $color-red;
    .badge-status {
        color: $color-red;
        border-color: $color-red;
    }
}
.list-col {
    flex: 1 1 auto;
    padding: 20px 25px;
    &:first-child {
        @include mediaMin($breakpoint-md) {
            padding-left: 40px;
        }
    }
    @include mediaMax($breakpoint-md) {
        padding: 14px 16px;
        &+.list-col {
            padding-top: 0;
        }
    }
}
@include mediaMin($breakpoint-md) {
    .list-col-center {
        text-align: center;
    }
    .list-col-right {
        text-align: right;
    }
}
.list-check {
    display: inline-flex;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,.15);
    color: rgba(255,255,255,.15);
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
.list-check-filled {
    background-color: #fff;
    color: $color-dark;
    border-color: transparent;
}
.list-col-status {
    @include mediaMax($breakpoint-md) {
        position: absolute;
        right: 0;
        top: 15px;
        z-index: -1;
    }
}
.due {
    font-family: $font-head;
    text-transform: uppercase;
    font-size: .9rem !important;
    color: rgba(255,255,255,.5);
    margin-top: 2px;
    span {
        color: #fff;
    }
}

$col-widths: 100, 150, 200, 250, 300, 350;
@include mediaMin($breakpoint-md) {

    @each $width in $col-widths {
        .list-col-#{$width} {
          width: #{$width}px;
          flex: 0 0 auto;
        }
    }

}
.list-row--priority {
    position: relative;
}
.list-row--priority:before {
    content: '!';
    position: absolute;
    left: 10px;
    top: 15px;
    left: 8px;
    top: 8px;
    font-size: 25px;
    color: $color-red;
}
.list-row {
    position: relative;
}
.list-row__menu {
    position: absolute;
    top: 0%;
    right: 10px;
    font-size: 20px;
    transition: all .5s;
    z-index: 1;
    height: 100%;
    
    

    img {
        width: 15px;
        margin-right: 10px;
    }
    img:last-child {
        margin-bottom: 0;
    }
}
.list-row__menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    span {
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background: rgba(#fff, 0.5);
        display: block;

        margin-right: 5px;
    }
}
.list-row__menu-btn:hover span {
    background: #fff;
}
.list-row {
    padding-right: 20px;
}
.list-row__menu-dropdown {
    background: #20202B; 
    position: absolute; 
    top: 65%;
    right: 30px;
    transform: translateY(105%) translateX(-21%);
    box-shadow: 0 0px 10px rgba(255, 255, 255, 0.1);
    z-index: 99;
    border-radius: 5px;
    min-width: 150px;
    visibility: hidden;
    opacity: 0;
    transform-origin: top center;
    transform: scale(.94) translateY(20px);

   
    
}
.list-row__menu.active .list-row__menu-dropdown {
    transition: .2s ease-out;
    visibility: visible;
    opacity: 1;
    transform: scale(1) translateY(0);
    
}
.list-row__menu-dropdown-item {
    display: flex;
    align-items: center;
    padding: 12px 30px;
    font-family: Oswald, Helvetica Neue, Helvetica, Arial, sans-serif;
    padding: 12px 30px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.2rem;
    font-size: 16px;

    span {

        //opacity: 0.5;
        //font-size: 13px;
    }
   

}
.list-row__menu-dropdown-item:hover {
    background: $color-dark;
    span {
        opacity: 1;
    }
}
// .list-row:hover .list-row__menu {
//     opacity: 1;
// }
/* ---- Theme Variables ---- */
/* Font */
/* Colors */
/* Transitions */
/* Element radius */
/*---- General - Theme Styles ----*/
html {
  font-size: 16px; }

html, body {
  min-height: 100%;
  height: 100%; }

body {
  background-color: #313140;
  font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #fff; }

p {
  line-height: 1.65em; }

h1, h2, h3, h4, h5, h6 {
  font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 700; }

table {
  width: 100%; }

a {
  color: #E4662A; }

a, .btn, button {
  transition: all 0.2s linear; }
  a:hover, .btn:hover, button:hover {
    text-decoration: none; }
  a:focus, .btn:focus, button:focus {
    outline: none;
    text-decoration: none;
    box-shadow: none; }

figcaption {
  padding-top: 8px;
  font-size: .85em; }

.text-muted {
  color: #78778E !important; }

.badge {
  font-weight: 400;
  border-radius: 3px;
  padding: 4px 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-transform: uppercase; }
  .badge.badge-success {
    background-color: rgba(0, 200, 83, 0.12);
    color: #00C853; }
  .badge.badge-danger {
    background-color: rgba(242, 71, 56, 0.12);
    color: #F24738; }

.progress {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 2px; }

.progress-bar,
.progress-bar--green {
  text-align: right;
  padding: 0 0px; }
  .progress-bar span,
  .progress-bar--green span {
    padding: 0 5px; }

.progress-bar {
  background-color: #E4662A; }

.progress-bar--green {
  background: #00C853; }

.text-danger {
  color: #F24738 !important; }

@media only screen and (max-width: 767px) {
  .container {
    max-width: unset; } }

/* Dropdown Menu */
.dropdown-menu {
  background-color: #20202B;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.125); }

.dropdown-item {
  font-family: Oswald, Helvetica Neue, Helvetica, Arial, sans-serif;
  padding: 12px 30px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.2rem; }
  .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.25);
    color: #fff; }
  @media only screen and (max-width: 992px) {
    .dropdown-item {
      padding: 20px 30px; } }

.dropdown-divider {
  border-top-color: rgba(255, 255, 255, 0.1);
  margin: 0; }

.dropdown-menu-animated {
  display: block;
  visibility: hidden;
  opacity: 0;
  transform-origin: top center;
  transform: scale(0.94) translateY(20px); }

.dropdown-menu--withicons > li > a {
  padding-right: 60px;
  position: relative; }
  .dropdown-menu--withicons > li > a [class^="icon-"] {
    position: absolute;
    right: 26px;
    top: 50%;
    transform: translateY(-50%);
    opacity: .35; }

.show > .dropdown-menu:not(.animated) {
  display: block; }

.show > .dropdown-menu-animated {
  transition: .2s ease-out;
  visibility: visible;
  opacity: 1;
  transform: scale(1) translateY(0); }

@media only screen and (max-width: 992px) {
  .dropdown-mobile .dropdown-menu {
    position: fixed;
    left: 0;
    bottom: 0;
    top: auto !important;
    width: 100vw;
    transform: translate3D(0, 20px, 0); }
  .dropdown-mobile .dropdown-item {
    padding: 20px 30px; }
  .dropdown-mobile.show .dropdown-menu {
    transform: translate3D(0, 0, 0); } }

/* Modal */
.modal .close {
  position: absolute;
  right: 0;
  top: 0;
  font-size: .85em;
  margin: 0;
  transition: .2s all;
  outline: none !important;
  text-shadow: none;
  color: rgba(255, 255, 255, 0.4);
  opacity: 1; }
  .modal .close i {
    transition: .2s all;
    display: inline-block; }
  .modal .close:hover {
    color: rgba(255, 255, 255, 0.75); }
    .modal .close:hover i {
      transform: scale(1.2); }

.modal-content {
  border: none;
  border-radius: 3px;
  position: relative;
  background-color: #313140; }

.modal-header {
  justify-content: center;
  border-bottom: none;
  position: relative;
  padding-top: 30px; }
  @media only screen and (min-width: 992px) {
    .modal-header {
      padding-left: 30px;
      padding-right: 30px; } }

.modal-title {
  text-align: center;
  font-size: .8em;
  flex: 1 1 auto;
  color: #78778E;
  text-transform: uppercase;
  font-family: 'mikeicon', sans-serif;
  letter-spacing: 1px;
  font-weight: 600; }

.modal-sub-title {
  text-align: center;
  color: #78778E;
  font-size: 15px; }

.modal-header--goals {
  padding-bottom: 5px; }

.modal-body .btn-primary.btn {
  background: #E4662A;
  color: #fff; }

.modal-body .form .form-group > label {
  text-transform: uppercase;
  font-family: Oswald,Helvetica Neue,Helvetica,Arial,sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 2px; }

.modal-body .custom-checkbox label {
  cursor: pointer; }

.modal-body select {
  cursor: pointer; }

@media only screen and (min-width: 992px) {
  .modal-body {
    padding-left: 30px;
    padding-right: 30px; } }

@media only screen and (min-width: 992px) {
  .modal-footer {
    padding-left: 30px;
    padding-right: 30px; } }

.modal-inner {
  max-width: 90%;
  margin: 0 auto; }

/* Nav Tabs */
.nav-tabs-simple {
  justify-content: center;
  border-bottom-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 20px; }
  .nav-tabs-simple .nav-item + .nav-item {
    margin-left: 20px; }
  .nav-tabs-simple .nav-link {
    color: rgba(255, 255, 255, 0.35);
    text-transform: uppercase;
    padding: 10px 0; }
    .nav-tabs-simple .nav-link:hover {
      color: rgba(255, 255, 255, 0.65);
      border-color: transparent; }
    .nav-tabs-simple .nav-link.active {
      color: #fff;
      background-color: transparent;
      border-color: transparent;
      box-shadow: inset 0 -2px 0 #fff; }
    @media only screen and (max-width: 992px) {
      .nav-tabs-simple .nav-link {
        font-size: .85rem; } }

/* Misc */
.not-visible {
  display: none; }

.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba(123, 198, 228, 0.35); }

.date {
  display: inline-flex;
  align-items: center; }

.date-day {
  font-size: 2.4rem !important;
  margin-right: 6px !important; }
  @media only screen and (max-width: 992px) {
    .date-day {
      font-size: 1.8rem !important; } }

.date-month {
  font-size: .9rem !important;
  line-height: 1em !important; }
  @media only screen and (max-width: 992px) {
    .date-month {
      font-size: .8rem !important; } }

/* Login */
.login {
  display: flex;
  height: 100%; }
  .login > * {
    flex: 0 0 auto;
    width: 70%; }
  .login h5 {
    font-size: 1.05em; }
  .login p {
    line-height: 1.3em; }
  .login .btn-group {
    display: flex; }
    .login .btn-group > * {
      flex: 1;
      padding-left: 12px;
      padding-right: 12px; }
  .login .btn-text {
    color: rgba(255, 255, 255, 0.65);
    border-bottom: 1px dotted rgba(255, 255, 255, 0.35); }
    .login .btn-text:hover {
      color: #fff;
      border-bottom-color: transparent; }
  .login .text-muted {
    color: #8e8d9d !important; }
  .login .small {
    font-size: .9em; }
  .login .login__footer {
    position: absolute;
    bottom: 0;
    width: 100%; }

.login__branding {
  position: fixed;
  top: 0;
  left: 30%;
  width: 70%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 35px; }
  .login__branding img {
    width: 70px;
    display: inline-block; }

.login__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-y: auto; }

.login__form {
  width: 100%;
  max-width: 315px;
  padding: 35px 0; }

.login__promo {
  background: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30%; }
  .login__promo::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(49, 49, 64, 0.75); }

.login__msg {
  padding: 35px 50px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  text-align: center;
  max-width: 550px;
  margin: 0 auto;
  font-size: 1.65em; }

.login__picto {
  width: 50px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 25px; }

.login__footer {
  font-size: .85em;
  padding: 25px 0;
  text-align: center; }
  .login__footer p {
    margin: 0; }

.text-or {
  margin: 35px 0;
  font-size: 11px;
  line-height: 1em;
  border-bottom: 1px solid rgba(120, 119, 142, 0.2);
  height: 7px; }
  .text-or span {
    background-color: #313140;
    padding: 0 15px; }

.login-slider {
  width: 100%;
  height: 100%;
  position: relative; }
  .login-slider .slick-list,
  .login-slider .slick-track {
    height: 100%; }

.login-slider__slide {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%; }

.slide--1 {
  background-image: url("../images/login-bg2.png"); }

.slide--2 {
  background-image: url("../images/login-bg4.png"); }

.slide--3 {
  background-image: url("../images/login-bg1.png"); }

.slide--4 {
  background-image: url("../images/login-bg2.png"); }

.slide--5 {
  background-image: url("../images/login-bg3.png"); }
  .slide--5 p {
    text-align: center; }

.slide--6 {
  background-image: url("../images/login-bg1.png"); }
  .slide--6 p {
    text-align: center; }

.login__msg {
  margin: 0 auto;
  display: block;
  width: 100%; }

.see-pricing {
  color: #fff;
  font-weight: 400;
  text-align: center;
  display: inline-block;
  font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  position: relative;
  opacity: 0.8; }

.see-pricing:hover {
  color: #fff;
  opacity: 1; }

.see-pricing:after {
  content: "\e911";
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-45%);
  font-family: "mikeicon";
  transition: all .5s; }

.see-pricing:hover:after {
  transform: translateY(-45%) translateX(3px); }

.see-pricing-wrapper {
  display: flex;
  justify-content: center; }

@media only screen and (max-width: 992px) {
  .login__branding {
    left: 0%;
    width: 100%;
    position: static; }
  .login__promo {
    display: none; }
  .login > * {
    width: 100%; }
  .login__content {
    justify-content: flex-start;
    padding: 0 15px; } }

@media screen and (max-height: 850px) {
  .login__branding {
    position: static; }
  .login__form {
    padding-top: 0; }
  .login__content {
    justify-content: flex-start; }
  .login .login__footer {
    position: static; } }
